import React, {useEffect, useState} from "react";
import { AbstractCase } from "../../../types/Entities/AbstractCase";
import ProgressBar from "../../ProgressBar/ProgressBar";
import { Box, Paper } from "@mui/material";
import LegalbirdLoader from "../../ContentLoader/LegalbirdLoader";
import Container from "../../Container/Container";
import { getFinishedStep } from "../../../services/documentUploadFormService";
import pickDocumentIcon from "../../../assets/img/progress/icon_progressbar_vertrag-upload.png";
import furtherDocumentsIcon from "../../../assets/img/progress/icon_add_documents.png";
import addNoteIcon from "../../../assets/img/progress/icon_letter_150px.png";
import finishedIcon from "../../../assets/img/progress/icon_formsteps_finished.png";
import StepSwitch from "./StepSwitch";
import { getMainAttachmentStep, getMessageStep } from "../../../services/messagingService";
import {MessageCreateData} from "../../../types/Messaging/MessageCreateData";
import useMediaObjectCombinationRequest from "../../../hooks/useMediaObjectCombinationRequest";

//progressbar step indices
const MESSAGE = 0;
const ATTACHMENT = 1;
const FURTHER_ATTACHMENTS = 2;
const FINISHED = 3;

const progressBarSteps = [
  {
    key: "note",
    name: "Nachricht verfassen",
    icon: addNoteIcon,
    progress: 15,
  },
  {
    key: "mainDocument",
    name: "Datei anhängen",
    icon: pickDocumentIcon,
    progress: 40,
  },
  {
    key: "furtherDocuments",
    name: "Weitere Dateien",
    icon: furtherDocumentsIcon,
    progress: 65,
  },
  {
    key: "finished",
    name: "Nachricht versenden",
    icon: finishedIcon,
    progress: 100,
  },
];

type MessageFormProps = {
  product: AbstractCase;
};

export default function MessageForm({ product }: MessageFormProps) {
  const [steps, setSteps] = useState(4);
  const [currentStep, setCurrentStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [messageCreateData, setMessageCreateData] = useState<MessageCreateData | null>(null);

  const { mediaObjectCombinationRequests, isLoadingMediaObjectCombinationRequest, updateAction } = useMediaObjectCombinationRequest(
    product,
    ['message']
  );
  const getProgressBarStep = () => {
    switch (currentStep) {
      case getMessageStep():
        return progressBarSteps[MESSAGE];
      case getMainAttachmentStep():
        return progressBarSteps[ATTACHMENT];
      case getFinishedStep(steps):
        return progressBarSteps[FINISHED];
      default:
        return progressBarSteps[FURTHER_ATTACHMENTS];
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentStep]);

  const progressBarStep = getProgressBarStep();

  return (
    <Container>
      <ProgressBar steps={progressBarSteps} active={progressBarStep.key} progress={progressBarStep.progress} />
      <Paper sx={{ padding: "2rem" }}>
        <Box sx={{ maxWidth: 650, margin: "0 auto" }}>
          {(isLoading || isLoadingMediaObjectCombinationRequest) ? (
            <LegalbirdLoader />
          ) : (
            <StepSwitch
              messageCreateData={messageCreateData}
              setMessageCreateData={setMessageCreateData}
              currentStep={currentStep}
              product={product}
              setCurrentStep={setCurrentStep}
              steps={steps}
              setSteps={setSteps}
              setIsLoading={setIsLoading}
              mediaObjectCombinationRequest={mediaObjectCombinationRequests.message}
              mediaObjectCombinationRequestUpdateAction={updateAction}
            />
          )}
        </Box>
      </Paper>
    </Container>
  );
}
