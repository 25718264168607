import React from "react";
import { Paper, Grid, Typography } from "@mui/material";
import advantagesBoxStyle from "./advantagesBoxStyle";

import costWeightedImage from "../../../assets/icons/serviceOverview/icon_costs.svg";
import serviceWeightedImage from "../../../assets/icons/serviceOverview/icon_servicewelt.svg";
import assistanceWeightedImage from "../../../assets/icons/serviceOverview/icon_assistant_smile.svg";

type AdvantagesBoxProps = {
  isPaper?: boolean;
};

const AdvantagesBox = ({ isPaper = true }: AdvantagesBoxProps) => {
  const elevation = isPaper ? 4 : 0;

  return (
    <Paper sx={advantagesBoxStyle.box} elevation={elevation}>
      <Typography variant="h4" sx={advantagesBoxStyle.boxHeading}>
        Ihr Rundum-Sorglos-Paket bei Legalbird
      </Typography>
      <Grid container>
        <Grid item xs={12} sm={4} sx={advantagesBoxStyle.gridItem}>
          <Paper elevation={4} sx={advantagesBoxStyle.imageCircleContainer}>
            <img src={costWeightedImage} alt="Transparente Kosten" />
          </Paper>
          <Typography variant="h4">Niedrige Kosten</Typography>
          <div>Wir berechnen nur die Mindestkosten - keine versteckten Gebühren!</div>
        </Grid>
        <Grid item xs={12} sm={4} sx={advantagesBoxStyle.gridItem}>
          <Paper elevation={4} sx={advantagesBoxStyle.imageCircleContainer}>
            <img src={assistanceWeightedImage} alt="Servicebereich" />
          </Paper>
          <Typography variant="h4">Professionelle Beratung</Typography>
          <div>Wir garantieren professionelle und persönliche Beratung - jederzeit und ortsunabhängig!</div>
        </Grid>
        <Grid item xs={12} sm={4} sx={advantagesBoxStyle.gridItem}>
          <Paper elevation={4} sx={advantagesBoxStyle.imageCircleContainer}>
            <img src={serviceWeightedImage} alt="Ansprechpartner" />
          </Paper>
          <Typography variant="h4">Übersichtlicher Ablauf</Typography>
          <div>Ihr individueller Kundenbereich bietet Ihnen jederzeit den vollen Überblick!</div>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default AdvantagesBox;
