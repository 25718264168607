import React, { useEffect } from "react";
import { Badge, IconButton } from "@mui/material";
import { Mail } from "@mui/icons-material";
import { isCookieFetched, mercureFetchCookie, mercureSubscribe } from "../../../services/mercure";
import { useCustomer } from "../../../provider/Customer/CustomerProvider";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Conversation } from "../../../types/Entities/Messaging/Conversation";
import { fetchCollection, queryKeys } from "../../../services/reactQuery/reactQueryService";
import { Link } from "react-router-dom";

export default function MessageNotifications() {
  const cookieFetched = isCookieFetched();
  const { customer } = useCustomer();
  const queryClient = useQueryClient();

  const { data: conversations, isLoading } = useQuery<Array<Conversation>, Error>({
    queryKey: queryKeys.collection("/conversations"),
    queryFn : () => fetchCollection("/conversations"),
    staleTime: Infinity
  });

  useEffect(() => {
    if (cookieFetched) {
      const eventSource = mercureSubscribe("/messages/refresh/" + customer!.id, () => {
        queryClient.invalidateQueries(queryKeys.collection("/conversations"));
      });
      return () => {
        eventSource.close();
      };
    } else {
      mercureFetchCookie();
    }
  }, [cookieFetched]);

  if (isLoading || !conversations) {
    return null;
  }

  const newMessagesCount = conversations.reduce((accumulator, currentValue) => {
    return accumulator + currentValue.containedMessages.filter((message) => !message.readByMe).length;
  }, 0);

  return (
    <IconButton component={Link} to={"/servicewelt/nachrichten"}>
      <Badge badgeContent={newMessagesCount < 10 ? newMessagesCount : "9+"} color={"primary"}>
        <Mail sx={{ color: "grey" }} />
      </Badge>
    </IconButton>
  );
}
