import React from "react";
import * as PropTypes from "prop-types";
import teaserWbsStyle from "./teaserWbsStyle";
import { Box, Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import Container from "../../../../../../components/Container/Container";

export default function TeaserWbs({ imageLeftHandSide, image, imageAlt, headline, subText, button, variant }) {
  if (variant === "compact") {
    return (
      <Container>
        <Grid container flexDirection={imageLeftHandSide ? "row" : "row-reverse"} alignItems={"center"} spacing={1} maxWidth={"565px"}>
          <Grid item xs={12} sm={3}>
            <Box component={"img"} src={image} alt={imageAlt} sx={teaserWbsStyle.smallImage} />
          </Grid>
          <Grid item xs={12} sm={9} sx={teaserWbsStyle.textContent}>
            <Typography variant={"h4"} component={"h2"}>
              {headline}
            </Typography>
            <Typography variant={"body1"} sx={teaserWbsStyle.subText}>
              {subText}
            </Typography>
            {button}
          </Grid>
        </Grid>
      </Container>
    );
  }

  return (
    <Container>
      <Grid container flexDirection={imageLeftHandSide ? "row" : "row-reverse"} alignItems={"center"} spacing={1}>
        <Grid item xs={12} md={5}>
          <Box>
            <img src={image} alt={imageAlt} />
          </Box>
        </Grid>
        <Grid item xs={12} md={7} sx={teaserWbsStyle.textContent}>
          <Typography variant={"h2"}>{headline}</Typography>
          <Typography sx={teaserWbsStyle.subText}>{subText}</Typography>
          {button}
        </Grid>
      </Grid>
    </Container>
  );
}

TeaserWbs.propTypes = {
  imageLeftHandSide: PropTypes.bool,
  image: PropTypes.any.isRequired,
  imageAlt: PropTypes.string,
  headline: PropTypes.string.isRequired,
  subText: PropTypes.string.isRequired,
  button: PropTypes.element,
  variant: PropTypes.string,
};

TeaserWbs.defaultProps = {
  imageLeftHandSide: false,
  variant: "standard",
};
