import { Theme } from "@mui/material";

const gradientPaperStyle = {
  background: (theme: Theme) => ({
    background: "linear-gradient(#FFFFFF, #F4F7FF) !important",
    ...(theme.palette.mode === "dark"
      ? {
          background: "background.default",
        }
      : {}),
  }),
};

export default gradientPaperStyle;
