import { Theme } from "@mui/material";

const formLegendStyle = {
  fieldset: {
    width: "100%",
  },
  label: (theme: Theme) => ({
    fontSize: "1.25rem",
    color: "#333",
    textAlign: "center",
    padding: "0 1rem",
    lineHeight: 1.5,
    ...(theme.palette.mode === "dark"
      ? {
          color: "#F4F4F4",
        }
      : {}),
  }),
};
export default formLegendStyle;
