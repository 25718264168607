import React from "react";
import * as PropTypes from "prop-types";
import serviceWorldContentBoxStyle from "./serviceWorldContentBoxStyle";
import {Box, Grid, Paper} from "@mui/material";
import Typography from "@mui/material/Typography";

const ServiceWorldContentBox = ({ icon = null, altText = "Icon", headline, children }) => {
  return (
    <Paper elevation={4} sx={serviceWorldContentBoxStyle.contentBoxContainer}>
      <Grid container justifyContent={"center"} wrap={"nowrap"} sx={serviceWorldContentBoxStyle.contentBoxHeadline}>
        <Grid item>
          <Grid container alignItems={"center"}>
            {icon && (
              <Grid item sx={serviceWorldContentBoxStyle.imageCircleContainer}>
               <Box component={"img"} src={icon} alt={altText} sx={serviceWorldContentBoxStyle.headerIcon} />
              </Grid>
            )}
            <Grid item>
              <Typography variant={"h5"} component={"h3"} align={"center"} paddingX={"2rem"}>
                {headline}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {children}
    </Paper>
  );
};

ServiceWorldContentBox.propTypes = {
  icon: PropTypes.string,
  altText: PropTypes.string,
  headline: PropTypes.string.isRequired,
};

export default ServiceWorldContentBox;
