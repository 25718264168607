import React from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import checkedIcon from "../../../assets/icons/generic/icon_check.svg";
import {AbstractCase} from "../../../types/Entities/AbstractCase";

type MessageCompletedProps = {
  product: AbstractCase;
};

export default function MessageCompleted({ product }: MessageCompletedProps) {
  return (
    <Stack alignItems={"center"} spacing={3}>
      <Box component={"img"} sx={{ height: "100px", width: "100px" }} src={checkedIcon} />
      <Typography>Ihre Nachricht wurde erfolgreich an unser Team versendet.</Typography>
      <Button fullWidth={false} component={Link} to={"/servicewelt/nachrichten?caseId=" + product.id} variant={"contained"}>
        Zurück zur Nachrichtenseite
      </Button>
    </Stack>
  );
}
