import React from "react";
import serviceworldTeaserStyle from "./serviceworldTeaserStyle";
import Typography from "@mui/material/Typography";
import HeadlineSection from "../Sections/Generic/HeadlineSection/HeadlineSection";
import serviceworldImage from "../../assets/img/teaser/homepage/Smartphone_Mann_Servicewelt_combi.svg";
import { Stack } from "@mui/material";
import { Button } from "@mui/material";
import TextContainer from "../Container/TextContainer";
import { Link } from "react-router-dom";

export default function ServiceworldTeaser({}) {
  return (
    <TextContainer>
      <Stack alignItems={"center"}>
        <HeadlineSection headline={"Legen Sie jetzt Ihren Fall an"} image={serviceworldImage} typographyVariant={"h2"} smallImage={false} />
        <Typography variant={"subtitle2"} sx={serviceworldTeaserStyle.centeredText}>
          In ihrer persönlichen Servicewelt können Sie ganz einfach ihr Anliegen schildern, einen Beratungstermin buchen und von dort den Fortschritt des
          jeweiligen Falls mitverfolgen.
        </Typography>
        <Button variant={"contained"} color={"primary"} sx={serviceworldTeaserStyle.serviceworldButton} component={Link} to={"/servicewelt"}>
          Zu Ihrer Servicewelt
        </Button>
      </Stack>
    </TextContainer>
  );
}
