import moment from "moment";
import InquiryToCustomer, { RequestedDocument } from "../types/Entities/InquiryToCustomer";
import callIcon from "../assets/icons/generic/call_clock.svg";
import iconAssistent from "../assets/icons/serviceOverview/icon_assistant.svg";

export const allowMissingDocument = (documentData: RequestedDocument, inquiryToCustomer: InquiryToCustomer) => {
  // fallback for deprecated field. remove this in january 2024
  if (documentData.mandatory) {
    return false;
  }

  switch (documentData.mandatoryType) {
    case "full":
      return false;
    case "deadlineDependent":
      return moment() > moment(inquiryToCustomer.deadline);
    default:
      return true;
  }
};

export const getInquiriesBoxHeadingContent = (inquiries: InquiryToCustomer[]) => {
  const hasAppointmentInquiry = inquiries.some((inquiry) => inquiry.inquiryType === "appointment");
  const hasOtherInquiry = inquiries.some((inquiry) => inquiry.inquiryType !== "appointment");

  if (hasAppointmentInquiry && !hasOtherInquiry) {
    return { headline: "Termin vereinbaren", icon: callIcon };
  }

  return { headline: "Rückmeldung geben", icon: iconAssistent };
};
