import Grid from "@mui/material/Grid";
import ValidatorSelectField from "../../FormFields/ValidatorSelectField";
import MenuItem from "@mui/material/MenuItem";
import EmailCheck from "../../EmailCheck/EmailCheck";
import ButtonLoading from "../../Button/ButtonLoading";
import ButtonTextTermsOfServicePrivacy from "../Common/ButtonTextTermsOfServicePrivacy/ButtonTextTermsOfServicePrivacy";
import React from "react";
import { useForm } from "../../../provider/Form/FormProvider";
import { advocardFlexCheckinStyles } from "./advocardFlexCheckinStyle";
import {
  emailDefault,
  exactWrongStringLength,
  isNumber,
  matchRegexp,
  maxStringLength,
  minStringLength,
  requiredValidator,
} from "../../../services/validationRules";
import ValidatorTextField from "../../FormFields/ValidatorTextField";
import { useCustomer } from "../../../provider/Customer/CustomerProvider";
import Typography from "@mui/material/Typography";
import { Stack } from "@mui/material";
import InfoOutlined from "@mui/icons-material/InfoOutlined";

type AdvocardFlexCheckinFormProps = {
  emailErrorText: string;
  setEmailErrorText: (text: string) => void;
  isLoading: boolean;
  externalError: string;
};

const insurancePolicyNumberValidators = [
  requiredValidator("Bitte geben Sie Ihre Versicherungsscheinnummer an"),
  { validator: isNumber, message: "Bitte geben Sie nur Zahlen ein (0-9)" },
  { validator: minStringLength, params: [8], message: "Bitte geben Sie mindestens 8 Zeichen ein." },
  { validator: maxStringLength, params: [10], message: "Bitte geben Sie höchstens 10 Zeichen ein." },
  {
    validator: matchRegexp,
    params: ["^[2-9]\\d*$"],
    message: "Bitte kürzen Sie die führende 1 aus Ihrer Versicherungsscheinnummer",
  },
  {
    validator: exactWrongStringLength,
    params: [9],
    message:
      "Ihre Versicherungsschein-Nr. hat die falsche Länge. Bitte geben Sie die Versicherungsschein-Nr. mit 8 oder 10 Stellen ein.",
  },
];

export default function AdvocardFlexCheckinForm({ emailErrorText, setEmailErrorText, isLoading, externalError }: AdvocardFlexCheckinFormProps) {
  const { handleSubmit } = useForm();
  const { isLoggedIn } = useCustomer();

  return (
    <form onSubmit={handleSubmit}>
      <Grid container alignItems={"baseline"} justifyContent={"center"} columnSpacing={4}>
        <Grid item xs={12} sm={6} md={4}>
          <ValidatorSelectField
            label="Um welche Vertragsart geht es?"
            name={`contractType`}
            validators={[requiredValidator("Bitte geben Sie die Art des Vertrages an.")]}
          >
            <MenuItem value={"travelContract"}>Reisevertrag</MenuItem>
            <MenuItem value={"providerContract"}>Versorgervertrag</MenuItem>
            <MenuItem value={"membershipContract"}>Fitnessverträge</MenuItem>
            <MenuItem value={"subscriptionContract"}>Abofalle</MenuItem>
          </ValidatorSelectField>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <ValidatorTextField
            name="insurancePolicyNumber"
            label="Ihre Versicherungsscheinnummer"
            validators={insurancePolicyNumberValidators}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <ValidatorTextField
            name={`postalCode`}
            label={"Ihre Postleitzahl"}
            type={"number"}
            validators={[
              requiredValidator("Bitte geben Sie die Postleitzahl an"),
              {
                validator: matchRegexp,
                params: [/^[0-9]{5}$/],
                message: "Bitte geben Sie die Postleitzahl mit 5 Stellen ein",
              },
            ]}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={12}>
          <EmailCheck
            name="email"
            label="Ihre E-Mail-Adresse"
            autoComplete="email"
            emailErrorText={emailErrorText}
            setEmailErrorText={setEmailErrorText}
            validators={[requiredValidator("Bitte geben Sie Ihre E-Mail-Adresse an"), emailDefault]}
            redirectProps={{ pathname: "", search: "" }}
            disabled={isLoggedIn}
          />
        </Grid>
        <Grid item xs={12} sm={10}>
          {externalError && (
            <Stack direction={"row"} alignItems={"center"} spacing={2} margin={1}>
              <InfoOutlined color={"primary"} />
              <Typography sx={advocardFlexCheckinStyles.errorMessage}>{externalError}</Typography>
            </Stack>
          )}
          <ButtonLoading variant="contained" sx={advocardFlexCheckinStyles.button} type="submit" fullWidth isLoading={isLoading}>
            Service starten
          </ButtonLoading>
          <ButtonTextTermsOfServicePrivacy />
        </Grid>
      </Grid>
    </form>
  );
}
