import React, { useState } from "react";
import { Divider, IconButton, Menu, MenuItem } from "@mui/material";
import { Menu as MenuIcon } from "@mui/icons-material";
import * as messagesActions from "../../store/messages/actions";
import { logout } from "../../store/login/actions";
import { Link, useNavigate } from "react-router-dom";
import { useCustomer } from "../../provider/Customer/CustomerProvider";

export default function LoggedInMenu({}) {
  const { customer, refreshCustomer } = useCustomer();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    if (customer.verification === null) {
      messagesActions.addMessage({
        type: "info",
        text: "Bitte bestätigen Sie Ihre E-Mail-Adresse, damit Sie alle Funktionen der Servicewelt nutzen können.",
      });
      return;
    }

    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logout();
    refreshCustomer();
    handleCloseMenu();
    navigate("/");
  };

  return (
    <>
      <IconButton onClick={handleClick} size="large">
        <MenuIcon />
      </IconButton>
      <Menu
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        PaperProps={{
          style: { minWidth: 150 },
        }}
      >
        <MenuItem component={Link} to={"/servicewelt"}>
          Meine Fälle
        </MenuItem>
        <MenuItem component={Link} to={"/servicewelt/mein-konto"}>
          Mein Konto
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleLogout}>Abmelden</MenuItem>
      </Menu>
    </>
  );
}

LoggedInMenu.propTypes = {};

LoggedInMenu.defaultProps = {};
