import React from "react";
import Layout from "../../../../Layouts/Layout";
import LpDivorceSaskiaSchlemmerMeta from "./LpDivorceSaskiaSchlemmerMeta";
import LandingPageTeaser from "../../../../../components/Teaser/LandingPageTeaser";
import { useCustomer } from "../../../../../provider/Customer/CustomerProvider";
import Container from "../../../../../components/Container/Container";
import DivorceCheckin from "../../../../../components/Checkin/DivorceCheckin/DivorceCheckin";
import GreyBackgroundWrapper from "../../../../../components/BackgroundWrapper/GreyBackgroundWrapper";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import saskiaSchlemmerTheme from "./saskiaSchlemmerTheme";
import { useTheme } from "@mui/styles";
import logo from "../../../../../assets/img/landingpage/saskiaschlemmer/Logo_LP_Schlemmer.png";
import howItWorksLogo1 from "../../../../../assets/img/landingpage/saskiaschlemmer/icon_antrag_erstellen_schlemmer_v2.png";
import howItWorksLogo2 from "../../../../../assets/img/landingpage/saskiaschlemmer/icon_dokument_vv_schlemmer_v2.png";
import howItWorksLogo3 from "../../../../../assets/img/landingpage/saskiaschlemmer/icon_gerichtsgebaeude_schlemmer_v2.png";
import { Paper, Stack, Typography } from "@mui/material";
import lpSaskiaSchlemmerStyle from "./lpSaskiaSchlemmerStyles";
import TextContainer from "../../../../../components/Container/TextContainer";
import { FormatQuote } from "@mui/icons-material";
import ProcedureDivorceSection from "../../../../../components/Sections/Divorce/ProcedureDivorceSection/ProcedureDivorceSection";
import DivorcePlannerSection from "../../../../../components/Sections/Divorce/DivorcePlannerSection/DivorcePlannerSection";
import TrustSection from "../../../../../components/Sections/Generic/TrustSection/TrustSection";

export default function LpDivorceSaskiaSchlemmer() {
  const { isLoggedIn } = useCustomer();
  const theme = useTheme();

  return (
    <Layout>
      <ThemeProvider theme={createTheme(theme, saskiaSchlemmerTheme)}>
        <LpDivorceSaskiaSchlemmerMeta />
        <Stack direction={"row"} justifyContent={"center"}>
          <img src={logo} alt={"Saskia Schlemmer & Legalbird"} />
        </Stack>
        <LandingPageTeaser headline={"Scheidung einfach & online"} sx={{}} />
        <GreyBackgroundWrapper>
          {!isLoggedIn && (
            <Container>
              <DivorceCheckin partner={"saskiaSchlemmer"} idPrefix={"top"} checkinHeading={"Wir führen Sie Schritt für Schritt durch Ihre Scheidung"} />
            </Container>
          )}
        </GreyBackgroundWrapper>
        <TextContainer>
          <Paper sx={lpSaskiaSchlemmerStyle.recommendationBox}>
            <Paper sx={lpSaskiaSchlemmerStyle.recommendationTextBox}>
              <FormatQuote color={"secondary"} fontSize={"large"} />
              <Typography variant={"h3"} sx={lpSaskiaSchlemmerStyle.recommendationText}>
                Legalbird empfehle ich jedem, der sich professionell, unkompliziert und mit persönlicher Betreuung bei diesem Prozess begleiten lassen möchte!
              </Typography>
              <FormatQuote color={"secondary"} sx={{ margin: "0 0 0 auto", display: "block" }} fontSize={"large"} />
              <Typography variant={"subtitle2"} sx={lpSaskiaSchlemmerStyle.recommendationText}>
                Saskia Schlemmer, Rechtsanwältin
              </Typography>
            </Paper>
          </Paper>
        </TextContainer>
        <Container>
          <Typography variant={"h2"} textAlign={"center"}>
            Hinweis
          </Typography>
          <Typography variant={"subtitle1"} textAlign={"center"}>
            Ihr Scheidungsprozess wird von den erfahrenen Anwältinnen und Anwälten von Legalbird begleitet.
          </Typography>
        </Container>
        <ProcedureDivorceSection headline={"So einfach funktioniert es"} iconOverwrites={[howItWorksLogo1, howItWorksLogo2, howItWorksLogo3]} />
        <DivorcePlannerSection />
        <TrustSection />
        {!isLoggedIn && (
          <Container>
            <DivorceCheckin partner={"saskiaSchlemmer"} idPrefix={"top"} checkinHeading={"Wir führen Sie Schritt für Schritt durch Ihre Scheidung"} />
          </Container>
        )}
      </ThemeProvider>
    </Layout>
  );
}
