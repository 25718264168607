import React from "react";
import wizardStyle from "../wizardStyle";
import Paper from "@mui/material/Paper";
import FormFader from "../Common/Fader/FormFader";
import { Box, Grid, Hidden } from "@mui/material";
import FormSubmit from "../Common/FormSubmit/FormSubmit";
import Group from "../../Wizard/Group";
import PersonSvg from "../../../assets/icons/provision/inactive/person.svg";
import PersonSvgActive from "../../../assets/icons/provision/active/person.svg";
import LegalEntitySvg from "../../../assets/icons/contract/inactive/icon_firma.svg";
import LegalEntitySvgActive from "../../../assets/icons/contract/active/icon_firma_active.svg";
import Address from "../Common/Address/Address";
import Person from "../Common/Person/Person";
import { emailDefault, isPhoneNumberDefault, requiredValidator } from "../../../services/validationRules";
import { useForm } from "../../../provider/Form/FormProvider";
import ValidatorTextField from "../../FormFields/ValidatorTextField";
import Typography from "@mui/material/Typography";
import FormLegend from "../Common/FormLegend/FormLegend";
import PostOfficeBoxAddress from "../Common/Address/PostOfficeBoxAddress";
import Iconize from "../../SvgIcon/Iconize";
import CheckSvg from "../../../assets/icons/common/inactive/haken.svg";
import CheckSvgActive from "../../../assets/icons/common/active/haken.svg";
import CrossSvg from "../../../assets/icons/common/inactive/kreuz.svg";
import CrossSvgActive from "../../../assets/icons/common/active/kreuz.svg";

export default function ContractPartner({ submitProps }: any) {
  const { values, handleSubmit } = useForm();
  return (
    <Paper sx={wizardStyle.paper} elevation={4}>
      <FormFader>
        <Box component={"form"} sx={{ ...wizardStyle.applicationForm, ...wizardStyle.wizardForm }} onSubmit={handleSubmit}>
          <Hidden smUp>
            <Typography variant={"h4"} sx={wizardStyle.headline}>
              Ihr Vertrag
            </Typography>
          </Hidden>
          <Grid container justifyContent={"flex-start"} alignItems={"baseline"}>
            <Grid item xs={12}>
              <FormLegend>
                <strong>Angaben zu Ihrem Vermieter</strong>
              </FormLegend>
            </Grid>
            <Grid item xs={12} sx={[wizardStyle.gridTopSpaced, wizardStyle.question]}>
              <FormLegend>
                Damit wir rechtliche Schritte einleiten können, benötigen wir möglichst ausführliche Angaben zu Ihrem Vermieter. Die Daten sollten Sie in Ihrem
                Mietvertrag oder in anderem Schriftverkehr mit dem Vermieter finden können.
              </FormLegend>
            </Grid>
            <Grid item xs={12}>
              <Group
                type="radio"
                name="contractualPartnerType"
                question="Ist Ihr Vermieter eine Privatperson oder ein Unternehmen? WICHTIG: Hierbei geht es darum, mit wem Sie den Mietvertrag abgeschlossen haben. Geben Sie hier also bitte keine Hausverwaltung an."
                options={[
                  {
                    labelText: "Privatperson",
                    labelIcon: <Iconize src={PersonSvg} alt={"Nein"} />,
                    labelIconActive: <Iconize src={PersonSvgActive} alt={"Nein"} />,
                    value: "contractualPartnerPerson",
                  },
                  {
                    labelText: "Unternehmen",
                    labelIcon: <Iconize src={LegalEntitySvg} alt={"Ja"} />,
                    labelIconActive: <Iconize src={LegalEntitySvgActive} alt={"Ja"} />,
                    value: "contractualPartnerLegalEntity",
                  },
                ]}
                validators={[requiredValidator("Bitte wählen Sie eine der angezeigten Antworten aus")]}
              />
            </Grid>
            {values.contractualPartnerType === "contractualPartnerPerson" && (
              <>
                <Person path="contractualPartnerPerson" headline="Wie heißt Ihr Vermieter?" />
                <Grid item xs={12} sx={[wizardStyle.gridTopSpaced, wizardStyle.question]}>
                  <FormLegend>
                    Bitte geben Sie möglichst alle weiteren Daten an, die Ihnen vorliegen, damit wir schnellstmöglich für Sie tätig werden können.
                  </FormLegend>
                </Grid>
                <Address path="contractualPartnerPerson.residenceAddress" headline="" fieldsAreRequired={false} />
                <Grid item xs={12}>
                  <ValidatorTextField name="contractualPartnerPerson.email" label="E-Mail-Adresse" autoComplete="email" validators={[emailDefault]} />
                </Grid>
                <Grid item xs={12}>
                  <ValidatorTextField name="contractualPartnerPerson.telephone" label="Telefonnummer" autoComplete="tel" validators={isPhoneNumberDefault} />
                </Grid>
              </>
            )}
            {values.contractualPartnerType === "contractualPartnerLegalEntity" && (
              <>
                <Grid item xs={12} sx={[wizardStyle.gridTopSpaced, wizardStyle.question]}>
                  <FormLegend>Wie heißt das Unternehmen, das an Sie vermietet?</FormLegend>
                </Grid>
                <Grid item xs={12}>
                  <ValidatorTextField
                    name="contractualPartnerLegalEntity.legalName"
                    label="Name des Unternehmens"
                    helperText="Bitte geben Sie die vollständige Firma an, die auch im Mietvertrag oder im Briefkopf in Schriftsätzen genannt wird. Also z.B. Gebäudeservice GmbH"
                    validators={[requiredValidator()]}
                  />
                </Grid>
                <Grid item xs={12} sx={[wizardStyle.gridTopSpaced, wizardStyle.question]}>
                  <FormLegend>
                    Bitte geben Sie möglichst alle weiteren Daten an, die Ihnen vorliegen, damit wir schnellstmöglich für Sie tätig werden können.
                  </FormLegend>
                </Grid>
                <Address path="contractualPartnerLegalEntity.address" headline={""} fieldsAreRequired={false} />
                <PostOfficeBoxAddress path="contractualPartnerLegalEntity.address" headline={""} fieldsAreRequired={false} />
                <Grid item xs={12}>
                  <ValidatorTextField name="contractualPartnerLegalEntity.email" label="E-Mail-Adresse" autoComplete="email" validators={[emailDefault]} />
                </Grid>
                <Grid item xs={12}>
                  <ValidatorTextField
                    name="contractualPartnerLegalEntity.telephone"
                    label="Telefonnummer"
                    autoComplete="tel"
                    validators={isPhoneNumberDefault}
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <Group
                type="radio"
                name="hasPropertyManager"
                question={"Gibt es eine Hausverwaltung, die Ihren Vermieter vertritt und mit der Sie entsprechend zu allen Anliegen kommunizieren?"}
                options={[
                  {
                    labelText: "Ja",
                    labelIcon: <Iconize src={CheckSvg} alt={"Ja"} />,
                    labelIconActive: <Iconize src={CheckSvgActive} alt={"Ja"} />,
                    value: "yes",
                  },
                  {
                    labelText: "Nein",
                    labelIcon: <Iconize src={CrossSvg} alt={"Nein"} />,
                    labelIconActive: <Iconize src={CrossSvgActive} alt={"Nein"} />,
                    value: "no",
                  },
                ]}
                validators={[requiredValidator("Bitte wählen Sie eine der angezeigten Antworten aus")]}
              />
            </Grid>
            {values.hasPropertyManager === "yes" && (
              <>
                <Grid item xs={12} sx={[wizardStyle.gridTopSpaced, wizardStyle.question]}>
                  <FormLegend>
                    Bitte geben Sie möglichst alle Daten an, die Ihnen vorliegen, damit wir schnellstmöglich für Sie tätig werden und die Hausverwaltung direkt
                    kontaktieren können.
                  </FormLegend>
                </Grid>
                <Grid item xs={12}>
                  <ValidatorTextField
                    name="propertyManager.legalName"
                    label="Name Hausverwaltung"
                    helperText="Bitte geben Sie den vollständigen Firmennamen an, der im Briefkopf der Schreiben oder in der E-Mail-Signatur steht. Z.B.: Hausverwaltungs GmbH"
                    validators={[requiredValidator()]}
                  />
                </Grid>
                <Address path="propertyManager.address" headline={""} fieldsAreRequired={false} />
                <Grid item xs={12}>
                  <ValidatorTextField name="propertyManager.email" label="E-Mail-Adresse" autoComplete="email" validators={[emailDefault]} />
                </Grid>
                <Grid item xs={12}>
                  <ValidatorTextField name="propertyManager.telephone" label="Telefonnummer" autoComplete="tel" validators={isPhoneNumberDefault} />
                </Grid>
                <Grid item xs={12}>
                  <ValidatorTextField name="propertyManager.fax" label="Faxnummer" validators={isPhoneNumberDefault} />
                </Grid>
                <Grid item xs={12}>
                  <ValidatorTextField
                    name="propertyManagerReference"
                    label="Aktenzeichen Hausverwaltung"
                    helperText="Benutzt die Hausverwaltung in Schreiben an Sie im Betreff oder dem Briefkopf ein Zeichen/ eine Nummer?"
                  />
                </Grid>
              </>
            )}
          </Grid>
          <FormSubmit {...submitProps} />
        </Box>
      </FormFader>
    </Paper>
  );
}
