import React from "react";
import gradientPaperStyle from "./gradientPaperStyle";
import { Paper } from "@mui/material";

export default function GradientPaper({ sx, children, ...rest }) {
  return (
    <Paper sx={{ ...gradientPaperStyle.background, ...sx }} {...rest}>
      {children}
    </Paper>
  );
}
