import Iconize from "../../../../SvgIcon/Iconize";
import CheckSvg from "../../../../../assets/icons/common/inactive/haken.svg";
import CheckSvgActive from "../../../../../assets/icons/common/active/haken.svg";
import PartialSvg from "../../../../../assets/icons/common/inactive/icon_incomplete.svg";
import PartialSvgActive from "../../../../../assets/icons/common/active/icon_incomplete_active.svg";
import CrossSvg from "../../../../../assets/icons/common/inactive/kreuz.svg";
import CrossSvgActive from "../../../../../assets/icons/common/active/kreuz.svg";
import Grid from "@mui/material/Grid";
import React from "react";
import { requiredValidator } from "../../../../../services/validationRules";
import Group from "../../../../Wizard/Group";
import { AbstractCase } from "../../../../../types/Entities/AbstractCase";

type FinalClaimStatusClientProps = {
  product: AbstractCase;
  hasClaimStatusClient: boolean;
};
const FinalClaimStatusClient = ({ product, hasClaimStatusClient }: FinalClaimStatusClientProps) => {
  if (!hasClaimStatusClient) {
    return null;
  }

  return (
    <Grid item xs={12}>
      <Group
        type={"radio"}
        name={"finalClaimStatusClient"}
        question={"Wie ist der aktuelle Stand der Forderung aus Ihrer Sicht?"}
        options={[
          {
            labelText:
              product.caseCategory === "enforceClaim"
                ? "Die Forderung wird/wurde durch die Gegenseite voll erfüllt und der Fall kann abgeschlossen werden"
                : "Die Gegenseite hat die Forderung gegen mich vollständig aufgegeben und der Fall kann abgeschlossen werden. ",
            labelIcon: <Iconize src={CheckSvg} alt={"Ja"} />,
            labelIconActive: <Iconize src={CheckSvgActive} alt={"Ja"} />,
            value: "fullyAgreed",
          },
          {
            labelText:
              product.caseCategory === "enforceClaim"
                ? "Die Forderung wird/wurde durch die Gegenseite nur teilweise erfüllt"
                : "Die Gegenseite hat die Forderung gegen mich teilweise aufgegeben ",
            labelIcon: <Iconize src={PartialSvg} alt={"Nein"} />,
            labelIconActive: <Iconize src={PartialSvgActive} alt={"Nein"} />,
            value: "partiallyAgreed",
          },
          {
            labelText:
              product.caseCategory === "enforceClaim" ? "Die Forderung wird/wurde nicht erfüllt" : "Die Gegenseite hält vollständig an der Forderung fest",
            labelIcon: <Iconize src={CrossSvg} alt={"Nein"} />,
            labelIconActive: <Iconize src={CrossSvgActive} alt={"Nein"} />,
            value: "notAgreed",
          },
        ]}
        validators={[requiredValidator("Bitte wählen Sie eine der angezeigten Antworten aus")]}
      />
    </Grid>
  );
};
export default FinalClaimStatusClient;
