import React from "react";
import * as PropTypes from "prop-types";
import legalFieldPageStyle from "./legalFieldPageStyle";
import Layout from "../../Layouts/Layout";
import Typography from "@mui/material/Typography";
import GreyBackgroundWrapper from "../../../components/BackgroundWrapper/GreyBackgroundWrapper";
import ZipperTeaser from "../../../components/Teaser/ZipperTeaser";
import { legalFieldPageConfig } from "../../../services/landingPageService";
import ServiceworldTeaser from "../../../components/Teaser/ServiceworldTeaser";
import TrustSection from "../../../components/Sections/Generic/TrustSection/TrustSection";
import Container from "../../../components/Container/Container";
import {Box} from "@mui/material";
import {AbstractCase} from "../../../types/Entities/AbstractCase";

type LegalFieldPageProps = {
  productCategory: AbstractCase["productCategory"];
};

export default function LegalFieldPage({ productCategory }: LegalFieldPageProps) {
  const pageConfig = legalFieldPageConfig[productCategory];

  return (
    <Layout backgroundType={"landingpage"}>
      <Container>
        <Typography variant={"h1"} sx={legalFieldPageStyle.centeredText}>
          {pageConfig.headline}
        </Typography>
        <Typography variant={"subtitle2"} sx={legalFieldPageStyle.centeredText}>
          {pageConfig.subtitle}
        </Typography>
        <Box component={"img"} src={pageConfig.headlineImage} sx={legalFieldPageStyle.image} alt={"Teaser image"} />
      </Container>
      <GreyBackgroundWrapper hasVerticalPadding>
        <Container>
          <Typography variant={"h3"} sx={legalFieldPageStyle.centeredText}>
            Wir unterstützen Sie zum Beispiel bei den folgenden Themen:
          </Typography>
        </Container>
        <ZipperTeaser teaserRows={pageConfig.zipperTeaserRows} />
      </GreyBackgroundWrapper>
      <ServiceworldTeaser />
      <TrustSection />
    </Layout>
  );
}

LegalFieldPage.propTypes = {
  productCategory: PropTypes.string.isRequired,
};
