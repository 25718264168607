import React from "react";
import ContentBox from "../../Box/ContentBox/ContentBox";
import statusIcon from "../../../assets/icons/serviceOverview/icon_info.svg";
import Typography from "@mui/material/Typography";


export const StatusBoxDeactivatedProduct = () => {
  return (
    <ContentBox icon={statusIcon} headline={"Produkt nicht mehr unterstützt"} alignContent={"stretch"}>
      <Typography textAlign={"center"}>Dieses Produkt wird von uns nicht mehr angeboten. Bisher erhaltene Dokumente können sie im Bereich “Meine Dokumente” weiterhin abrufen.</Typography>
    </ContentBox>
  );
};
