import React from "react";
import mainTeaserStyle from "./mainTeaserStyle";
import {Box, Button, Grid} from "@mui/material";
import manWithPhone from "../../assets/img/teaser/homepage/Client_with_Smartphone.svg";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import { useCustomer } from "../../provider/Customer/CustomerProvider";
import { Link } from "react-router-dom";

export default function MainTeaser({ handleOpenLoginLayer }) {
  const { isLoggedIn } = useCustomer();

  return (
    <Box sx={mainTeaserStyle.mainTeaserContainer}>
      <Grid container>
        <Grid item xs={12} md={6}>
          <Typography variant={"h1"} sx={mainTeaserStyle.headline}>
            Recht leicht gemacht
          </Typography>
          <Typography color={"textSecondary"} sx={mainTeaserStyle.subText} variant={"subtitle1"}>
            Wir helfen Ihnen einfach, schnell und professionell. Egal wann und wo!
          </Typography>
          <Typography color={"textSecondary"} sx={mainTeaserStyle.subText} variant={"subtitle1"}>
            <strong>NEU:</strong> Illegale Datenweitergabe von Mobilfunkanbietern an die SCHUFA / CRIF prüfen lassen
          </Typography>
          {/*{!isLoggedIn && (
            <Button className={classes.button} variant={"contained"} color={"primary"} onClick={handleOpenLoginLayer}>
              Jetzt starten
            </Button>
          )}*/}
          <Button sx={mainTeaserStyle.button} variant={"contained"} color={"primary"} component={Link} to={"/lp/wbs/schufa"}>
            Jetzt starten
          </Button>
        </Grid>
        <Grid item xs={12} md={6}>
          <img src={manWithPhone} alt={"man with phone"} />
        </Grid>
      </Grid>
    </Box>
  );
}

MainTeaser.propTypes = {
  handleOpenLoginLayer: PropTypes.func.isRequired,
};
