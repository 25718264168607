const serviceWorldNewCaseStyle = {
  newCaseContainer: {
    margin: "25px 0",
  },
  bottomPadding: {
    paddingBottom: "1.6rem",
  },
  productCategoryHeadline: (theme) => ({
    display: "block",
    textAlign: "center",
    padding: "10px",
    backgroundColor: "background.light",
    ...(theme.palette.mode === "dark"
      ? {
          color: "#000000",
        }
      : {}),
  }),
  icon: {
    width: "30px",
    height: "30px",
    marginTop: "5px",
  },
  modalContent: {
    padding: "2rem 0",
  },
  infoIcon: {
    color: "#999999",
    margin: "0 0 0 auto",
  },
  overwriteLinkColor: {
    color: "buttonText.main",
  },
  productItem: {
    cursor: "pointer",
    padding: "15px 25px",
    "&:hover": (theme) => ({
      ...(theme.palette.mode === "dark"
        ? {
            backgroundColor: "#333333",
          }
        : {
            backgroundColor: "#d0e7e6",
          }),
    }),
    color: "text.primary",
  },
};

export default serviceWorldNewCaseStyle;
