/*
 * Copyright © 2023. Legalbird eine Marke der R&S Internet Jewels GmbH
 */

import React, { useEffect, useState } from "react";

import Typography from "@mui/material/Typography";
import { apiGet } from "../../services/apiClient";
import ButtonLoading from "../Button/ButtonLoading";
import ContentBox from "../Box/ContentBox/ContentBox";
import icon from "../../assets/icons/generic/icon_delete.svg";
import { AbstractCase } from "../../types/Entities/AbstractCase";
import { useNavigate } from "react-router-dom";

type ServiceWorldCaseDeleteProps = {
  product: AbstractCase;
  caseIsDeletable: boolean;
  setCaseIsDeletable: (isDeletable: boolean) => void;
};

export default function ServiceWorldCaseDelete({ product, caseIsDeletable, setCaseIsDeletable }: ServiceWorldCaseDeleteProps) {
  const [isLoading, setIsLoading] = useState(true);
  const [isDeleting, setIsDeleting] = useState(false);
  const navigate = useNavigate();

  async function checkDeletable() {
    const result = await apiGet(`/case_deletion/get_case_deletion_possible_status/${product.id}`);
    setCaseIsDeletable(!result.hasAcquisitionPartnerAdvocard && !result.isAdvancedCase && !result.hasBookingEntry);
    setIsLoading(false);
  }

  useEffect(() => {
    checkDeletable();
  }, []);

  if (isLoading || !caseIsDeletable) {
    return null;
  }

  async function deleteCase() {
    try {
      setIsDeleting(true);
      await apiGet(`/case_deletion/force/${product.id}`);
      navigate("/servicewelt");
    } finally {
      setIsDeleting(false);
    }
  }

  return (
    <ContentBox icon={icon} headline={"Fall löschen"} alignContent={"stretch"}>
      <Typography marginBottom={2}>
        Sie benötigen diesen Fall nicht (mehr) und möchten diesen daher löschen? Dann können Sie dies hier tun. <strong>Wichtig:</strong> Dies kann nicht
        rückgängig gemacht werden und alle mit dem Fall verbundenen Daten und Dokumente werden gelöscht!
      </Typography>
      <ButtonLoading isLoading={isDeleting} variant={"contained"} onClick={deleteCase} fullWidth={true}>
        Löschen
      </ButtonLoading>
    </ContentBox>
  );
}
