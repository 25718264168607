import React from "react";
import * as PropTypes from "prop-types";
import serviceWorldCaseItemStyle from "./serviceWorldCaseItemStyle";
import Divider from "@mui/material/Divider";
import {Box, Grid, ListItemButton} from "@mui/material";
import { Link } from "react-router-dom";
import { getProductData } from "../../../services/productService";
import Typography from "@mui/material/Typography";

const ServiceWorldCaseItem = ({ minimalCase, withDivider = true, listItemButtonConfig = {}, NotificationComponent = null }) => {
  const isLink = !!listItemButtonConfig.getLink;

  let conditionalProps = {};

  if (isLink) {
    conditionalProps.component = Link;
    conditionalProps.to = listItemButtonConfig.getLink(minimalCase);
  }

  if (!!listItemButtonConfig.getIsSelected) {
    conditionalProps.selected = listItemButtonConfig.getIsSelected(minimalCase);
  }

  if (!!listItemButtonConfig.handleClick) {
    conditionalProps.onClick = () => listItemButtonConfig.handleClick(minimalCase);
  }

  return (
    <>
      {withDivider && <Divider sx={serviceWorldCaseItemStyle.divider} />}
      <ListItemButton {...conditionalProps} sx={serviceWorldCaseItemStyle.caseItem}>
        <Typography sx={serviceWorldCaseItemStyle.reference}>{minimalCase.reference}</Typography>
        <Grid container alignItems={"center"} sx={serviceWorldCaseItemStyle.innerContainer} wrap={"nowrap"}>
          <Grid item>
            <Box component={"img"} src={getProductData(minimalCase.name, "productCategory.icon")} sx={serviceWorldCaseItemStyle.icon} alt={"Produkt Icon"} />
          </Grid>
          <Grid item sx={serviceWorldCaseItemStyle.textContainer}>
            <Typography variant={"h6"}>{getProductData(minimalCase.name, "productCategory.label")}</Typography>
            <Typography>
              {getProductData(minimalCase.name, "label")}
              {minimalCase.opposingParty ? ` - ${minimalCase.opposingParty}` : ""}
            </Typography>
          </Grid>
          {NotificationComponent && <NotificationComponent minimalCase={minimalCase} />}
        </Grid>
      </ListItemButton>
    </>
  );
};

ServiceWorldCaseItem.propTypes = {
  minimalCase: PropTypes.object.isRequired,
  withDivider: PropTypes.bool,
  selected: PropTypes.bool,
};

export default ServiceWorldCaseItem;
