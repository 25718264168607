import React from "react";
import * as PropTypes from "prop-types";
import pageNavigationStyle from "./pageNavigationStyle";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { HashLink as Link } from "react-router-hash-link";
import _map from "lodash/map";
import _upperCase from "lodash/toUpper";
import { Box, Divider, List, ListItem } from "@mui/material";
import Typography from "@mui/material/Typography";

const PageNavigation = ({ navigationLinks, headerIcon, headline, subHeadline }) => {
  return (
    <Paper elevation={4} sx={pageNavigationStyle.navigationPaper}>
      <List disablePadding>
        <ListItem sx={pageNavigationStyle.headlineListItem}>
          <Grid container justifyContent={"center"} alignItems={"center"} wrap={"nowrap"}>
            <Grid item>
              <Box sx={pageNavigationStyle.imageContainer}>
                <Box component={"img"} src={headerIcon} sx={pageNavigationStyle.headerIcon} alt={""} />
              </Box>
            </Grid>
            <Grid item sx={pageNavigationStyle.headlineContainer}>
              <Typography variant={"h5"}>{headline}</Typography>
              <Box sx={pageNavigationStyle.subHeadline}>{subHeadline}</Box>
            </Grid>
          </Grid>
        </ListItem>
        {_map(navigationLinks, (navigationLink) => (
          <React.Fragment key={navigationLink.label}>
            <Divider />
            <ListItem
              sx={[pageNavigationStyle.listItem, pageNavigationStyle.navigationLink]}
              to={navigationLink.target}
              component={Link}
              secondaryAction={navigationLink.icon ?? null}
            >
              {_upperCase(navigationLink.label)}
            </ListItem>
          </React.Fragment>
        ))}
      </List>
    </Paper>
  );
};

PageNavigation.propTypes = {
  navigationLinks: PropTypes.array.isRequired,
  headerIcon: PropTypes.string.isRequired,
  subHeadline: PropTypes.string,
};

export default PageNavigation;
