import React, { useState } from "react";
import ContentBox from "../../Box/ContentBox/ContentBox";
import { Box, Rating } from "@mui/material";
import TextField from "@mui/material/TextField";
import ButtonLoading from "../../Button/ButtonLoading";
import _head from "lodash/head";
import _filter from "lodash/filter";
import iconReview from "../../../assets/icons/serviceOverview/icon_review.svg";
import CustomerFeedbackProvenExpertBox from "./CustomerFeedbackProvenExpertBox";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { fetchCollection, queryKeys, updateResource } from "../../../services/reactQuery/reactQueryService";
import _set from "lodash/set";
import _merge from "lodash/merge";
import { AbstractCase } from "../../../types/Entities/AbstractCase";

export default function CustomerFeedbackBox({ product }: AbstractCase) {
  const urlParams = {
    source: "fullReview",
    caseId: product.id,
  };
  const { data: feedbackCollection, isLoading: queryIsLoading } = useQuery(queryKeys.collection("/customer_feedbacks", urlParams), () =>
    fetchCollection("/customer_feedbacks", urlParams)
  );
  const queryClient = useQueryClient();
  const updateMutation = useMutation(updateResource);

  const [isLoading, setIsLoading] = useState(false);
  const [values, setValues] = useState({
    stars: 0,
    comment: "",
  });

  if (queryIsLoading || !feedbackCollection) {
    return null;
  }

  const filteredFeedbackCollection = _filter(
    feedbackCollection,
    (feedbackEntity) => !feedbackEntity.feedbackDateTime || feedbackEntity.showProvenExpertRatingRequest
  );

  if (filteredFeedbackCollection.length === 0) {
    return null;
  }

  const currentFeedback = _head(filteredFeedbackCollection);
  const handleSubmit = async () => {
    setIsLoading(true);

    let payload: { stars: number; comment: string; showProvenExpertRatingRequest?: boolean } = {
      stars: values.stars,
      comment: values.comment,
    };

    if (values.stars === 5 && currentFeedback.isFinalReview) {
      payload.showProvenExpertRatingRequest = true;
    }

    await updateMutation.mutateAsync({
      uri: "/customer_feedbacks",
      id: currentFeedback.id,
      data: payload,
    });
    await queryClient.invalidateQueries(queryKeys.collection("/customer_feedbacks", urlParams));
    setIsLoading(false);
  };

  if (currentFeedback.showProvenExpertRatingRequest) {
    return <CustomerFeedbackProvenExpertBox product={product} customerFeedback={currentFeedback} />;
  }

  const handleChangeComment = ({ target }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setValues((prevData) => {
      return _set(_merge({}, prevData), "comment", target.value);
    });
  };

  const handleChangeRating = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    setValues((prevData) => {
      return _set(_merge({}, prevData), "stars", parseInt(target.value));
    });
  };

  return (
    <ContentBox icon={iconReview} headline={"Bitte bewerten Sie uns"}>
      <p>Wie zufrieden waren Sie bisher mit dem durch uns erbrachten Service? Wir freuen uns über Ihre Bewertung.</p>
      <Box sx={{ textAlign: "center" }}>
        <Rating name="stars" value={values.stars} onChange={(e) => handleChangeRating(e as React.ChangeEvent<HTMLInputElement>)} size="large" />
      </Box>
      <TextField
        label="Ihr Kommentar"
        name="comment"
        value={values.comment}
        onChange={handleChangeComment}
        multiline
        rows="4"
        maxRows="4"
        margin="normal"
        fullWidth
      />
      <ButtonLoading fullWidth color="primary" variant="contained" isLoading={isLoading} disabled={values.stars < 1} onClick={handleSubmit}>
        Bewertung abgeben
      </ButtonLoading>
    </ContentBox>
  );
}
