/*
 * Copyright © 2024. Legalbird eine Marke der R&S Internet Jewels GmbH
 */

import React, { FormEventHandler, useEffect } from "react";
import Paper from "@mui/material/Paper";
import FrankRiedelImage from "../../../assets/img/jobs/frankriedel.jpg";
import Grid from "@mui/material/Grid";
import ButtonLoading from "../../Button/ButtonLoading";
import { useNavigate } from "react-router-dom";
import powerOfAttorneyStyle from "./powerOfAttorneyStyle";
import PoaAgreementText from "../Common/PowerOfAttorney/PoaAgreementText";
import HintBox from "../../Box/HintBox/HintBox";
import Signature from "../Common/Signature/Signature";
import { useForm } from "../../../provider/Form/FormProvider";
import moment from "moment";
import Typography from "@mui/material/Typography";
import { getCaseLink } from "../../../services/productService";
import HighlightBox from "../../Box/HighlightBox/HighlightBox";
import { Box } from "@mui/material";
import { AbstractCase } from "../../../types/Entities/AbstractCase";
import FormSubmitProps from "../../../types/FormSubmitProps";
import { FormChangeEvent } from "../../../types/FormDataTypes";

type PowerOfAttorneyProps = {
  product: AbstractCase;
  submitProps: FormSubmitProps;
};

export default function PowerOfAttorney({ product, submitProps }: PowerOfAttorneyProps) {
  const navigate = useNavigate();
  const { handleSubmit, handleChange, values } = useForm();

  const { powerOfAttorney } = values;
  useEffect(() => {
    if (powerOfAttorney === null) {
      handleChange({
        target: {
          name: "powerOfAttorney",
          value: moment().format("DD.MM.YYYY"),
        },
      } as FormChangeEvent);
    }
  }, [powerOfAttorney]);

  const handlePoaSubmit: FormEventHandler<HTMLFormElement> = async (event: React.FormEvent<HTMLFormElement>) => {
    if (!submitProps.isMinimal) {
      await handleSubmit(event);
      return;
    }

    await handleSubmit(event, { preventRedirect: true });
    navigate(getCaseLink(product) + "/fall-vollstaendig");
  };

  return (
    <>
      {!submitProps.isMinimal && (
        <HintBox headline={"So geht es weiter"}>
          <Grid container>
            <Grid item sm={2} xs={12} sx={powerOfAttorneyStyle.imgCircleContainer}>
              <Paper elevation={4} sx={powerOfAttorneyStyle.imageCircleContainer}>
                <img src={FrankRiedelImage} alt={"Rechtsanwalt Frank Riedel"} />
              </Paper>
            </Grid>
            <Grid item sm={10} xs={12} sx={powerOfAttorneyStyle.text}>
              <ol>
                <li>
                  <Typography>Sie erteilen uns die Vollmacht, damit wir Sie vertreten dürfen.</Typography>
                </li>
                <li>
                  <Typography>Wir prüfen Ihre rechtlichen Möglichkeiten und melden uns dann umgehend bei Ihnen zurück.</Typography>
                </li>
                <li>
                  <Typography>
                    Wenn keine offenen Fragen mehr zu klären sind, setzen wir uns mit Ihrem Vermieter in Verbindung und versuchen Ihren Fall außergerichtlich zu
                    klären.
                  </Typography>
                </li>
                <li>
                  <Typography>
                    Sollte eine außergerichtliche Klärung nicht möglich sein, vertreten wir Sie selbstverständlich auch im gerichtlichen Verfahren.
                  </Typography>
                </li>
              </ol>
            </Grid>
          </Grid>
          {product.insurance.iIWPI && (
            <HighlightBox textColor={"defaultText"} headline={""}>
              {`Sie sind bei der ${product.insurance.insurance.name} rechtsschutzversichert, daher ist das weitere Vorgehen für Sie komplett kostenlos!`}
            </HighlightBox>
          )}
          {product.insurance.iIWPI === false && product.legalExpenseInsurance && (
            <HighlightBox textColor={"defaultText"} headline={""}>
              Sie haben angegeben, dass Sie bei {product.insurance.insurance.name} rechtsschutzversichert sind, daher müssen Sie sich über Kosten keinerlei
              Sorgen machen! Wir stellen Deckungsanfrage bei Ihrer Versicherung und kümmern uns um alles Weitere bezüglich der Abrechnung.
            </HighlightBox>
          )}
        </HintBox>
      )}
      <Paper sx={powerOfAttorneyStyle.paper} elevation={4}>
        <Box component={"form"} sx={powerOfAttorneyStyle.applicationForm} id={"application-form"} onSubmit={handlePoaSubmit}>
          <Typography variant={"h4"} sx={powerOfAttorneyStyle.poaHeadline}>
            Vollmacht
          </Typography>
          <div>
            <Typography paragraph>
              Hiermit {product.additionalClients!.length ? "erteilen wir" : "erteile ich"} der Legalbird Rechtsanwalts UG (haftungsbeschränkt) Vollmacht zu{" "}
              {product.additionalClients!.length ? "unserer" : "meiner"} Verteidigung und Vertretung, mit der Ermächtigung zur Bestellung von
              Unterbevollmächtigten.
            </Typography>
            <Typography paragraph>
              Die Vollmacht gilt in Sachen
              <br />
              {product.clientVsOpponentString}
              <br />
              wegen aller Ansprüche aus dem Mietverhältnis zwischen den zuvor genannten Parteien aus dem Mietvertrag vom{" "}
              {moment(product.contractDate).format("DD.MM.YYYY")}.
            </Typography>
            <Typography paragraph>Die Vollmacht ermächtigt zu allen die Angelegenheit betreffenden Handlungen, insbesondere zur</Typography>
            <ul>
              <li>
                <Typography>außergerichtlichen Interessensvertretung,</Typography>
              </li>
              <li>
                <Typography>Prozessführung einschließlich Einlegung und Rücknahme von Rechtsmitteln,</Typography>
              </li>
              <li>
                <Typography>Entgegennahme von Wertsachen und Übergabe der Mietsachen,</Typography>
              </li>
              <li>
                <Typography>Entgegennahme von Zustellungen,</Typography>
              </li>
              <li>
                <Typography>Empfangnahme von Geldern,</Typography>
              </li>
              <li>
                <Typography>Ausspruch jeglicher Form der Beendigung des Mietverhältnisses und</Typography>
              </li>
              <li>
                <Typography>
                  Begründung und Aufhebung von Vertragsverhältnissen und Abgabe von einseitigen Willenserklärungen, insbesondere zum Ausspruch von ordentlichen
                  und außerordentlichen Kündigungen.
                </Typography>
              </li>
            </ul>
            <Typography paragraph>Die Vollmacht erstreckt sich auch auf Nebenverfahren mit Ausnahme der Zwangsvollstreckung.</Typography>
            <Typography paragraph>
              {product.additionalClients!.length ? "Uns" : "Mir"} ist bekannt, dass mit der Vollmacht eine Beauftragung zu{" "}
              {product.additionalClients!.length ? "unserer" : "meiner"} Verteidigung und damit eine Anwaltsvergütung entsteht, deren Höhe sich nach den
              gesetzlichen Vorschriften richtet.
            </Typography>
            <PoaAgreementText lawFirmName={product.lawFirm.name} multipleClients={!!product.additionalClients!.length} />
          </div>
          {!product.clientIsCustomer && (
            <Box sx={{ marginTop: "1rem" }}>
              <HighlightBox textColor={"defaultText"} headline={""}>
                <Typography>
                  Achtung: Hier muss die Person unterschreiben, die den Vertrag geschlossen hat. In Ihrem Fall also:{" "}
                  {product.client.gender === "male" ? "Herr" : "Frau"} {product.client.givenName} {product.client.familyName}
                </Typography>
              </HighlightBox>
            </Box>
          )}
          {product.signatories!.map((signatory, index) => (
            <Signature key={index} product={product} person={signatory} />
          ))}
          <Grid container>
            <Grid item xs={12}>
              <ButtonLoading
                color="primary"
                variant="contained"
                type="submit"
                fullWidth={true}
                disabled={!product.client.signature}
                isLoading={submitProps.isLoading}
              >
                Vollmacht erteilen und Fall prüfen lassen
              </ButtonLoading>
            </Grid>
          </Grid>
          {product.insurance.iIWPI === false && product.legalExpenseInsurance && (
            <Box paddingTop={3} textAlign={"center"}>
              <HighlightBox textColor={"defaultText"} headline={""}>
                Sie haben angegeben, dass Sie bei {product.insurance.insurance.name} rechtsschutzversichert sind, daher müssen Sie sich über Kosten keinerlei
                Sorgen machen! Wir stellen Deckungsanfrage bei Ihrer Versicherung und kümmern uns um alles Weitere bezüglich der Abrechnung.
              </HighlightBox>
            </Box>
          )}
        </Box>
      </Paper>
    </>
  );
}
