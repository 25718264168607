import React from "react";
import gradientPaperStyle from "./gradientPaperStyle";
import { Card } from "@mui/material";

export default function GradientCard({ sx = {}, children, ...rest }) {
  return (
    <Card sx={{ ...gradientPaperStyle.background, ...sx }} {...rest}>
      {children}
    </Card>
  );
}
