import React from "react";
import Grid from "@mui/material/Grid";
import Iconize from "../../SvgIcon/Iconize";
import CheckSvg from "../../../assets/icons/common/inactive/haken.svg";
import CheckSvgActive from "../../../assets/icons/common/active/haken.svg";
import CrossSvg from "../../../assets/icons/common/inactive/kreuz.svg";
import CrossSvgActive from "../../../assets/icons/common/active/kreuz.svg";
import MoneySvg from "../../../assets/icons/contract/inactive/icon_geld-einfordern.svg";
import MoneySvgActive from "../../../assets/icons/contract/active/icon_geld-einfordern_active.svg";
import GoodsSvg from "../../../assets/icons/contract/inactive/icon_ware-einfordern.svg";
import GoodsSvgActive from "../../../assets/icons/contract/active/icon_ware-einfordern_active.svg";
import MoneyAndGoodsSvg from "../../../assets/icons/contract/inactive/icon_geld-und-leistung_einfordern.svg";
import MoneyAndGoodsSvgActive from "../../../assets/icons/contract/active/icon_geld-und-leistung_einfordern_active.svg";
import QuestionmarkSvg from "../../../assets/icons/common/inactive/fragezeichen.svg";
import QuestionmarkSvgActive from "../../../assets/icons/common/active/fragezeichen.svg";
import InputAdornment from "@mui/material/InputAdornment";
import { dateFieldDefault, isIban, isMoney, pastOrSameDateDefault, requiredValidator } from "../../../services/validationRules";
import { useForm } from "../../../provider/Form/FormProvider";
import ValidatorTextField from "../../FormFields/ValidatorTextField";
import Group from "../../Wizard/Group";
import FormLegend from "../Common/FormLegend/FormLegend";
import { IbanInput } from "../../FormFields/MaskedInputs/IbanInput";
import ValidatorDateField from "../../FormFields/ValidatorDateField";
import { translate } from "../../../services/translations/translations";

const ContractBaseEnforceClaim = () => {
  const { values } = useForm();

  return (
    <>
      <Grid item xs={12}>
        <Group
          type="radio"
          name="subjectOfDispute"
          question={translate("rentalContract.subjectOfDispute.question", values)}
          options={[
            {
              labelText: translate("rentalContract.subjectOfDispute.options.money.label", values),
              labelIcon: <Iconize src={MoneySvg} alt={"Geld"} />,
              labelIconActive: <Iconize src={MoneySvgActive} alt={"Geld"} />,
              value: "money",
            },
            {
              labelText: translate("rentalContract.subjectOfDispute.options.goods.label", values),
              labelIcon: <Iconize src={GoodsSvg} alt={"Leistung"} />,
              labelIconActive: <Iconize src={GoodsSvgActive} alt={"Leistung"} />,
              value: "goods",
            },
            {
              labelText: translate("rentalContract.subjectOfDispute.options.moneyAndGoods.label", values),
              labelIcon: <Iconize src={MoneyAndGoodsSvg} alt={"GeldUndLeistung"} width={300} />,
              labelIconActive: <Iconize src={MoneyAndGoodsSvgActive} alt={"GeldUndLeistung"} width={300} />,
              value: "moneyAndGoods",
            },
            {
              labelText: translate("rentalContract.subjectOfDispute.options.other.label", values),
              labelIcon: <Iconize src={QuestionmarkSvg} alt={"Sonstiges"} />,
              labelIconActive: <Iconize src={QuestionmarkSvgActive} alt={"Sonstiges"} />,
              value: "other",
            },
          ]}
          validators={[requiredValidator("Bitte wählen Sie eine der angezeigten Antworten aus")]}
        />
      </Grid>
      {(values.subjectOfDispute === "money" || values.subjectOfDispute === "moneyAndGoods") && (
        <>
          <Grid item xs={12}>
            <FormLegend>Wie viel Geld möchten Sie einfordern?</FormLegend>
          </Grid>
          <Grid item xs={12}>
            <ValidatorTextField
              label="Forderung in €"
              name="claimedAmountClient"
              validators={[
                {
                  validator: isMoney,
                  message: "Bitte geben Sie den Wert in Euro und Cent ein - zum Beispiel 136,15 oder 85,00",
                },
              ]}
              helperText="Bitte geben Sie den Wert in Euro und Cent ein - zum Beispiel 136,15 oder 85,00. Wenn Sie den Betrag noch nicht genau wissen, lassen Sie das Feld gerne einfach leer"
            />
          </Grid>
          <Grid item xs={12}>
            <FormLegend>Wie lautet Ihre Bankverbindung, auf die der geforderte Betrag überwiesen werden soll?</FormLegend>
          </Grid>
          <Grid item xs={12}>
            <ValidatorTextField
              label="IBAN für Rückerstattung"
              name="clientBankData.iban"
              validators={[
                {
                  validator: isIban,
                  message: "Die eingegebene IBAN ist ungültig",
                },
              ]}
              InputProps={{
                startAdornment: <InputAdornment position="start">DE</InputAdornment>,
                inputComponent: IbanInput,
              }}
              helperText={"Wenn Sie die IBAN gerade nicht wissen, lassen Sie das Feld gerne einfach leer."}
            />
          </Grid>
        </>
      )}
      <Grid item xs={12}>
        <Group
          type="radio"
          name="clientHasAlreadyClaimed"
          question="Haben Sie bereits schriftlich eine Forderung an Ihren Vermieter gestellt?"
          options={[
            {
              labelText: "Ja, ich habe bereits etwas eingefordert.",
              labelIcon: <Iconize src={CheckSvg} alt={"Ja"} />,
              labelIconActive: <Iconize src={CheckSvgActive} alt={"Ja"} />,
              value: "yes",
            },
            {
              labelText: "Nein, ich habe bisher nichts eingefordert.",
              labelIcon: <Iconize src={CrossSvg} alt={"Nein"} />,
              labelIconActive: <Iconize src={CrossSvgActive} alt={"Nein"} />,
              value: "no",
            },
          ]}
          validators={[requiredValidator("Bitte wählen Sie eine der angezeigten Antworten aus")]}
        />
      </Grid>
      {values.clientHasAlreadyClaimed === "yes" && (
        <>
          <Grid item xs={12}>
            <FormLegend>Wann haben Sie schriftlich etwas eingefordert?</FormLegend>
          </Grid>
          <Grid item xs={12}>
            <ValidatorDateField
              name="claimedDateClient"
              label="Datum des Forderungsschreibens"
              validators={[requiredValidator("Bitte geben Sie das Datum an"), dateFieldDefault, pastOrSameDateDefault]}
            />
          </Grid>
          <Grid item xs={12}>
            <ValidatorDateField
              name="claimedDeadlineClient"
              label="Frist im Forderungsschreiben "
              validators={[dateFieldDefault]}
              helperText={"Wenn Sie keine Frist gesetzt haben, dann lassen Sie das Feld einfach leer."}
            />
          </Grid>
        </>
      )}
    </>
  );
};
export default ContractBaseEnforceClaim;
