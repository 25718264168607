import React from "react";
import teaserStyle from "./teaserStyle";
import Teaser from "./Teaser";
import limitlessImage from "../../assets/img/teaser/homepage/Smartphone_Frau_Ohne_Grenzen_combi.svg";
import serviceworldImage from "../../assets/img/teaser/homepage/Smartphone_Mann_Servicewelt_combi.svg";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";

export default function ServiceTeaser({}) {
  return (
    <>
      <Teaser
        subText={
          "Bei uns gibt es Experten für jeden Rechtsbereich und wir arbeiten komplett digital. Das heißt für Sie: " +
          "Keine langen Wartezeiten mehr und kein lästiger Papierkram. Sie können sich jederzeit und von überall um Ihren Rechtsfall kümmern!"
        }
        headline={"Ohne Grenzen"}
        imageAlt={"Woman with phone"}
        image={limitlessImage}
        imageLeftHandSide
      />
      <Teaser
        subText={"In wenigen Minuten Ihren Fall prüfen lassen. Kurz Fall schildern, Dokumente hochladen und wir erledigen den Rest für Sie!"}
        headline={"Ihre persönliche Servicewelt"}
        imageAlt={"servicewelt"}
        image={serviceworldImage}
        button={
          <Button component={Link} color={"primary"} variant={"contained"} to={"/servicewelt"} sx={teaserStyle.button}>
            Ihre Servicewelt
          </Button>
        }
      />
    </>
  );
}
