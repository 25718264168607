const buttonTextTermsOfServicePrivacyStyles = {
  termsOfServiceText: {
    fontSize: 12,
    textAlign: "center",
    marginTop: "10px",
  },
  sslLockIcon: {
    height: "12px",
  },
};

export default buttonTextTermsOfServicePrivacyStyles;
