import React from "react";
import processBoxStyle from "./processBoxStyle";
import {Grid, Typography} from "@mui/material";
import _map from "lodash/map";
import { getProcessConfig } from "../../../services/serviceWorldService";
import iconCheck from "../../../assets/icons/serviceOverview/icon_check.svg";
import iconProgress from "../../../assets/icons/serviceOverview/icon_progress.svg";
import { ProcessBoxLoader } from "../../ContentLoader/ProcessBoxLoader";
import ContentBox from "../../Box/ContentBox/ContentBox";
import PropTypes from "prop-types";

const ProcessBox = ({ product }) => {
  if (!product) {
    return (
      <ContentBox icon={iconProgress} headline={"Prozessablauf"} alignContent={"stretch"} loading={!product}>
        <ProcessBoxLoader />
      </ContentBox>
    );
  }

  if (product.isCustomerDataCompleted) {
    return null;
  }

  const processConfig = getProcessConfig(product);

  if (!processConfig) {
    return null;
  }

  return (
    <ContentBox icon={iconProgress} headline={processConfig.headline} alignContent={"stretch"} loading={!product}>
      <Grid container>
        {_map(processConfig.steps, (step, index) => (
          <React.Fragment key={index}>
            <Grid item xs={2}>
              <Grid container sx={processBoxStyle.iconContainer} direction="column" alignItems="center">
                {index === 0 ? <Grid item sx={processBoxStyle.connectorLineInvisible} /> : <Grid item sx={processBoxStyle.connectorLine} />}
                <Grid item sx={processBoxStyle.progressIcons}>
                  <img src={step.done ? iconCheck : step.icon} alt="Step Icon" />
                </Grid>
                {index === processConfig.steps.length - 1 ? (
                  <Grid item sx={processBoxStyle.connectorLineInvisible} />
                ) : (
                  <Grid item sx={processBoxStyle.connectorLine} />
                )}
              </Grid>
            </Grid>
            <Grid item xs={10} sx={[processBoxStyle.textBlock, step.done ? processBoxStyle.done : null]}>
              <Typography sx={processBoxStyle.stepHeading}>{step.headline}</Typography>
              <Typography sx={processBoxStyle.stepText}>{step.content}</Typography>
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
    </ContentBox>
  );
};

ProcessBox.propTypes = {
  product: PropTypes.object.isRequired,
};

export default ProcessBox;
