import React, { ElementType } from "react";
import headlineSectionStyle from "./headlineSectionStyle";
import Typography, { TypographyProps } from "@mui/material/Typography";
import Container from "../../../Container/Container";
import { Box } from "@mui/material";

type HeadlineSectionProps = {
  headline: string;
  typographyVariant?: ElementType & TypographyProps["variant"];
  typographyComponent?: ElementType;
  image?: string;
  smallImage?: boolean;
};

export default function HeadlineSection({ headline, typographyVariant = "h1", image, smallImage = true, typographyComponent }: HeadlineSectionProps) {
  return (
    <Container>
      <Typography variant={typographyVariant} sx={headlineSectionStyle.centeredText} component={typographyComponent || typographyVariant}>
        {headline}
      </Typography>
      {image && <Box component={"img"} src={image} alt={"teaser"} sx={smallImage ? headlineSectionStyle.smallImage : headlineSectionStyle.image} />}
    </Container>
  );
}
