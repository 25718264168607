import quotationMarkBackground from "../../../assets/img/background/Anfuehrungszeichen_oben_unten.svg";
import { Theme } from "@mui/material";

const quotationCardStyle = {
  card: (theme: Theme) => ({
    width: "200px",
    height: "200px",
    backgroundImage: "url(" + quotationMarkBackground + ")",
    backgroundColor: "linear-gradient(#FFFFFF, #F4F7FF)",
    backgroundRepeat: "no-repeat",
    margin: "auto",
    ...(theme.palette.mode === "dark"
      ? {
          backgroundColor: "linear-gradient(#333333, #222222)",
        }
      : {}),
  }),
  textContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    padding: "0 1.5rem",
  },
};

export default quotationCardStyle;
