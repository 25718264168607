import React from "react";
import { Box, Grid, Hidden } from "@mui/material";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import wizardStyle from "../wizardStyle";
import { useForm } from "../../../provider/Form/FormProvider";
import FormFader from "../Common/Fader/FormFader";
import FormLegend from "../Common/FormLegend/FormLegend";
import { translate } from "../../../services/translations/translations";
import { requiredValidator } from "../../../services/validationRules";
import FormSubmit from "../Common/FormSubmit/FormSubmit";
import Iconize from "../../SvgIcon/Iconize";
import ToolsSvg from "../../../assets/icons/tenancyLaw/rentalContract/inactive/handwerker.svg";
import ToolsSvgActive from "../../../assets/icons/tenancyLaw/rentalContract/active/handwerker.svg";
import RentIncreaseSvg from "../../../assets/icons/provider/inactive/price_increase_inactive.svg";
import RentIncreaseSvgActive from "../../../assets/icons/provider/active/price_increase_active.svg";
import UtilitiesStatementSvg from "../../../assets/icons/provider/inactive/provider_wrong_invoice_inactive.svg";
import UtilitiesStatementSvgActive from "../../../assets/icons/provider/active/provider_wrong_invoice_active.svg";
import DepositSvg from "../../../assets/icons/contract/inactive/icon_geld-einfordern.svg";
import DepositSvgActive from "../../../assets/icons/contract/active/icon_geld-einfordern_active.svg";
import LandlordHasCancelledSvg from "../../../assets/icons/provider/inactive/provider_cancellation_inactive.svg";
import LandlordHasCancelledSvgActive from "../../../assets/icons/provider/active/provider_cancellation_active.svg";
import IllegalContractClauseSvg from "../../../assets/icons/provision/inactive/mietvertrag.svg";
import IllegalContractClauseSvgActive from "../../../assets/icons/provision/active/mietvertrag.svg";
import QuestionMarkSvg from "../../../assets/icons/common/inactive/fragezeichen.svg";
import QuestionMarkSvgActive from "../../../assets/icons/common/active/fragezeichen.svg";
import Group from "../../Wizard/Group";
import RentalContractReasonForDisputeDetails from "./RentalContractReasonForDisputeDetails";

export default function RentalContractDetails({ submitProps }: any) {
  const { values, handleSubmit } = useForm();
  return (
    <Paper sx={wizardStyle.paper} elevation={4}>
      <FormFader>
        <Box component={"form"} sx={{ ...wizardStyle.applicationForm, ...wizardStyle.wizardForm }} onSubmit={handleSubmit}>
          <Hidden smUp>
            <Typography variant={"h4"} sx={wizardStyle.headline}>
              Ihr Vertrag
            </Typography>
          </Hidden>
          <Grid container justifyContent={"flex-start"} alignItems={"baseline"}>
            <Grid item xs={12}>
              <FormLegend>
                <strong>Details zu Ihrem Anliegen</strong>
              </FormLegend>
            </Grid>

            <Grid item xs={12}>
              <Group
                type="radio"
                name="reasonForDispute"
                question={translate(`rentalContract.reasonForDispute.question`, values)}
                options={[
                  {
                    labelText: translate(`rentalContract.reasonForDispute.options.housingDeficiencies.label`, values),
                    labelIcon: <Iconize src={ToolsSvg} alt={"housingDeficiencies"} />,
                    labelIconActive: <Iconize src={ToolsSvgActive} alt={"housingDeficiencies"} />,
                    value: "housingDeficiencies",
                  },
                  {
                    labelText: translate(`rentalContract.reasonForDispute.options.rentIncrease.label`, values),
                    labelIcon: <Iconize src={RentIncreaseSvg} alt={"rentIncrease"} />,
                    labelIconActive: <Iconize src={RentIncreaseSvgActive} alt={"rentIncrease"} />,
                    value: "rentIncrease",
                  },
                  {
                    labelText: translate(`rentalContract.reasonForDispute.options.utilitiesStatement.label`, values),
                    labelIcon: <Iconize src={UtilitiesStatementSvg} alt={"utilitiesStatement"} />,
                    labelIconActive: <Iconize src={UtilitiesStatementSvgActive} alt={"utilitiesStatement"} />,
                    value: "utilitiesStatement",
                  },
                  {
                    labelText: translate(`rentalContract.reasonForDispute.options.deposit.label`, values),
                    labelIcon: <Iconize src={DepositSvg} alt={"deposit"} />,
                    labelIconActive: <Iconize src={DepositSvgActive} alt={"deposit"} />,
                    value: "deposit",
                  },
                  {
                    labelText: translate(`rentalContract.reasonForDispute.options.landlordHasCancelled.label`, values),
                    labelIcon: <Iconize src={LandlordHasCancelledSvg} alt={"landlordHasCancelled"} />,
                    labelIconActive: <Iconize src={LandlordHasCancelledSvgActive} alt={"landlordHasCancelled"} />,
                    value: "landlordHasCancelled",
                  },
                  {
                    labelText: translate(`rentalContract.reasonForDispute.options.illegalContractClause.label`, values),
                    labelIcon: <Iconize src={IllegalContractClauseSvg} alt={"illegalContractClause"} />,
                    labelIconActive: <Iconize src={IllegalContractClauseSvgActive} alt={"illegalContractClause"} />,
                    value: "illegalContractClause",
                  },
                  {
                    labelText: translate(`rentalContract.reasonForDispute.options.other.label`, values),
                    labelIcon: <Iconize src={QuestionMarkSvg} alt={"other"} />,
                    labelIconActive: <Iconize src={QuestionMarkSvgActive} alt={"other"} />,
                    value: "other",
                  },
                ]}
                validators={[requiredValidator("Bitte wählen Sie eine der angezeigten Antworten aus")]}
              />
            </Grid>
            <RentalContractReasonForDisputeDetails values={values} />
          </Grid>
          <FormSubmit {...submitProps} />
        </Box>
      </FormFader>
    </Paper>
  );
}
