import React from "react";
import serviceworldStepSectionStyle from "./serviceworldStepSectionStyle";
import HeadlineSection from "../HeadlineSection/HeadlineSection";
import {Box, Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import TripleImageProgressionTeaser from "../../../TripleImageProgressionTeaser/TripleImageProgressionTeaser";
import couchImage from "../../../../assets/img/teaser/contact/sign_up.svg";
import workTogetherImage from "../../../../assets/img/teaser/contact/work_together.svg";
import checklistImage from "../../../../assets/img/teaser/contact/checklist.svg";
import Container from "../../../Container/Container";
import { Button, Hidden } from "@mui/material";
import { Link } from "react-router-dom";

export default function ServiceworldStepSection({}) {
  return (
    <Container>
      <Stack alignItems={"center"}>
        <HeadlineSection headline={"Ihre Servicewelt"} typographyVariant={"h2"} />
        <Typography variant={"subtitle1"} sx={serviceworldStepSectionStyle.centerText}>
          In unserer Servicewelt können Sie in wenigen einfachen Schritten Ihre Fälle anlegen und jederzeit den aktuellen Stand einsehen.
        </Typography>
        <Box sx={serviceworldStepSectionStyle.marginTop}>
          <TripleImageProgressionTeaser
            step1={{
              headline: "Anmelden",
              subHeadline: "Registrieren Sie sich einfach in unserer Servicewelt",
              image: couchImage,
            }}
            step2={{
              headline: "Daten angeben",
              subHeadline: "Geben Sie dort Ihre fallrelevanten Daten ein",
              image: checklistImage,
            }}
            step3={{
              headline: "Bearbeitung",
              subHeadline: "Unsere Anwälte lösen im engen Kontakt Ihren Rechtsfall",
              image: workTogetherImage,
            }}
          />
        </Box>
        <Hidden lgDown>
          <Button variant={"contained"} color={"primary"} component={Link} to={"/servicewelt"} sx={serviceworldStepSectionStyle.button}>
            Zu Ihrer Servicewelt
          </Button>
        </Hidden>
      </Stack>
    </Container>
  );
}
