import React from "react";
import { Route, Routes } from "react-router-dom";
import GuidebookEmploymentLawOverview from "../../containers/Pages/Settlement/GuidebookSettlementOverview/GuidebookEmploymentLawOverview";
import GuidebookSettlementCalculation from "../../components/GuidebookContent/GuidebookSettlementContent/GuidebookSettlementCalculation";
import GuidebookSettlementProcedure from "../../components/GuidebookContent/GuidebookSettlementContent/GuidebookSettlementProcedure";
import GuidebookSettlementTax from "../../components/GuidebookContent/GuidebookSettlementContent/GuidebookSettlementTax";
import GuidebookSettlementCosts from "../../components/GuidebookContent/GuidebookSettlementContent/GuidebookSettlementCosts";
import GuidebookKindsOfNotices from "../../components/GuidebookContent/GuidebookSettlementContent/GuidebookKindsOfNotices";
import GuidebookCoronaTermination from "../../components/GuidebookContent/GuidebookSettlementContent/GuidebookCoronaTermination";

export default function GuidebookEmploymentLaw() {
  return (
    <Routes>
      <Route path={`berechnung`} element={<GuidebookSettlementCalculation />} />
      <Route path={`ablauf`} element={<GuidebookSettlementProcedure />} />
      <Route path={`steuern`} element={<GuidebookSettlementTax />} />
      <Route path={`kosten`} element={<GuidebookSettlementCosts />} />
      <Route path={`kuendigungsarten`} element={<GuidebookKindsOfNotices />} />
      <Route path={`kuendigung-durch-corona`} element={<GuidebookCoronaTermination />} />
      <Route path={""} element={<GuidebookEmploymentLawOverview />} />
    </Routes>
  );
}
