const processBoxStyle = {
  stepHeading: {
    fontWeight: 500,
  },
  stepText: {
    margin: 0,
  },
  textBlock: {
    marginTop: "10px",
    marginBottom: "10px",
    paddingLeft: "25px",
  },
  done: {
    textDecoration: "line-through",
  },
  iconContainer: {
    width: "70px",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    alignContent: "center",
    alignItems: "center",
  },
  connectorLine: {
    flexGrow: 1,
    borderLeft: "1px solid #333",
  },
  connectorLineInvisible: {
    flexGrow: 1,
  },
  progressIcons: {
    margin: "5px 0",
    "& img": {
      width: "40px",
    },
  },
};

export default processBoxStyle;
